import { appendName } from "@/store/utils";
import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_MONITORING_GENERAL: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_MONITORING_GENERAL"),
  CALL_GET_INSURANCE: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_INSURANCE"),
  CALL_GET_CUSTOMER_VISIT: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_CUSTOMER_VISIT"),
  CALL_GET_POST_CLOSE: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_POST_CLOSE"),

  SAVE_MONITORING_GENERAL: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_MONITORING_GENERAL"),

  CREATE_CUSTOMER_VISIT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_CUSTOMER_VISIT"),
  CREATE_POST_CLOSE: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_POST_CLOSE"),
  CREATE_INSURANCE: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_INSURANCE"),

  UPDATE_CUSTOMER_VISIT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_CUSTOMER_VISIT"),
  UPDATE_POST_CLOSE: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_POST_CLOSE"),
  UPDATE_INSURANCE: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_INSURANCE")
};

export const mutations = {
  //> monitoring general
  SET_LOADING_MONITORING_GENERAL: appendToLoading("MONITORING_GENERAL"),
  SET_ERROR_MONITORING_GENERAL: appendToError("MONITORING_GENERAL"),
  SET_INFORMATION_MONITORING_GENERAL: appendToSetDetail("MONITORING_GENERAL"),
  //> post close
  SET_LOADING_POST_CLOSE: appendToLoading("POST_CLOSE"),
  SET_ERROR_POST_CLOSE: appendToError("POST_CLOSE"),
  SET_LIST_POST_CLOSE: appendToList("POST_CLOSE"),
  //> customer visit
  SET_LOADING_CUSTOMER_VISIT: appendToLoading("CUSTOMER_VISIT"),
  SET_ERROR_CUSTOMER_VISIT: appendToError("CUSTOMER_VISIT"),
  SET_LIST_CUSTOMER_VISIT: appendToList("CUSTOMER_VISIT"),
  //> insurance
  SET_LOADING_INSURANCE: appendToLoading("INSURANCE"),
  SET_ERROR_INSURANCE: appendToError("INSURANCE"),
  SET_LIST_INSURANCE: appendToList("INSURANCE"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("OTHER")
};

export const DEFAULT_STATE = {
  loading: { monitoringGeneral: false, customerVisit: false, postClose: false, insurance: false },
  error: { monitoringGeneral: null, customerVisit: null, postClose: null, insurance: null },
  list: { customerVisit: [], postClose: [], insurance: [] },
  data: null
};
