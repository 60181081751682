import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_MONITORING_GENERAL]: function(state, loading) {
    Vue.set(state.other.loading, "monitoringGeneral", loading);
  },
  [MU.SET_LOADING_CUSTOMER_VISIT]: function(state, loading) {
    Vue.set(state.other.loading, "customerVisit", loading);
  },
  [MU.SET_LOADING_POST_CLOSE]: function(state, loading) {
    Vue.set(state.other.loading, "postClose", loading);
  },
  [MU.SET_LOADING_INSURANCE]: function(state, loading) {
    Vue.set(state.other.loading, "insurance", loading);
  },
  [MU.SET_ERROR_MONITORING_GENERAL]: function(state, error) {
    Vue.set(state.other.error, "monitoringGeneral", error);
  },
  [MU.SET_ERROR_CUSTOMER_VISIT]: function(state, error) {
    Vue.set(state.other.error, "customerVisit", error);
  },
  [MU.SET_ERROR_POST_CLOSE]: function(state, error) {
    Vue.set(state.other.error, "postClose", error);
  },
  [MU.SET_ERROR_INSURANCE]: function(state, error) {
    Vue.set(state.other.error, "insurance", error);
  },
  [MU.SET_INFORMATION_MONITORING_GENERAL]: function(state, data) {
    Vue.set(state.other, "data", data);
  },
  [MU.SET_LIST_CUSTOMER_VISIT]: function(state, data) {
    Vue.set(state.other.list, "customerVisit", data);
  },
  [MU.SET_LIST_POST_CLOSE]: function(state, data) {
    Vue.set(state.other.list, "postClose", data);
  },
  [MU.SET_LIST_INSURANCE]: function(state, data) {
    Vue.set(state.other.list, "insurance", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "other", clone(DEFAULT_STATE));
  }
};
