import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LIST]: function(state, list) {
    Vue.set(state.list, "table", list);
  },
  [MU.SET_ERROR_LIST]: function(state, error) {
    Vue.set(state.error, "table", error);
  },
  [MU.SET_LOADING_LIST]: function(state, loading) {
    Vue.set(state.loading, "table", loading);
  },
  [MU.SET_CURRENT_ACTIVITY]: function(state, list) {
    Vue.set(state.list, "currentActivity", list);
  },
  [MU.SET_ERROR_CURRENT_ACTIVITY]: function(state, error) {
    Vue.set(state.error, "currentActivity", error);
  },
  [MU.SET_LOADING_CURRENT_ACTIVITY]: function(state, loading) {
    Vue.set(state.loading, "currentActivity", loading);
  },
  [MU.SET_MONTH_DATE]: function(state, list) {
    Vue.set(state.list, "monthDate", list);
  },
  [MU.SET_ERROR_MONTH_DATE]: function(state, error) {
    Vue.set(state.error, "monthDate", error);
  },
  [MU.SET_LOADING_MONTH_DATE]: function(state, loading) {
    Vue.set(state.loading, "monthDate", loading);
  },
  [MU.SET_CURRENT_LOAN]: function(state, payload) {
    Vue.set(state, "current", payload);
  },
  [MU.SET_SPREAD]: function(state, list) {
    Vue.set(state.list, "spread", list);
  },
  [MU.SET_ERROR_SPREAD]: function(state, error) {
    Vue.set(state.error, "spread", error);
  },
  [MU.SET_LOADING_SPREAD]: function(state, loading) {
    Vue.set(state.loading, "spread", loading);
  },
  [MU.SET_DEBTOR]: function(state, list) {
    Vue.set(state.list, "debtor", list);
  },
  [MU.SET_ERROR_DEBTOR]: function(state, error) {
    Vue.set(state.error, "debtor", error);
  },
  [MU.SET_LOADING_DEBTOR]: function(state, loading) {
    Vue.set(state.loading, "debtor", loading);
  },
  [MU.SET_NOTIFYING_LO]: function(state, notifying) {
    Vue.set(state.notifying, "lo", notifying);
  },
  [MU.SET_NOTIFYING_CLIENT]: function(state, notifying) {
    Vue.set(state.notifying, "client", notifying);
  },
  [MU.SET_NOTE]: function(state, note) {
    Vue.set(state, "note", note);
  },
  [MU.SET_ERROR_NOTE]: function(state, error) {
    Vue.set(state.error, "note", error);
  },
  [MU.SET_LOADING_NOTE]: function(state, loading) {
    Vue.set(state.loading, "note", loading);
  }
};
