import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const LoanStatsModule = {
  state: {
    loading: { table: false, currentActivity: false, monthDate: false, spread: false, debtor: false, note: false },
    error: { table: null, currentActivity: null, monthDate: null, spread: null, debtor: null, note: null },
    list: { table: [], currentActivity: [], monthDate: [], spread: [], debtor: [] },
    current: null,
    notifying: { lo: false, client: false },
    note: null
  },
  actions,
  mutations,
  getters
};
