import { appendName } from "../utils";

const append = appendName("LOAN_STATS");

export const actions = {
  GET_CALL_BORROWER_PRIMARY_LOAN: append("GET_CALL_BORROWER_PRIMARY_LOAN"),
  GET_CALL_LOAN_STATS_LIST: append("GET_CALL_LOAN_STATS_LIST"),
  GET_CALL_LOAN_CURRENT_ACTIVITY_SUMMARY_TAB_INFORMATION: append(
    "GET_CALL_LOAN_CURRENT_ACTIVITY_SUMMARY_TAB_INFORMATION"
  ),
  GET_CALL_LOAN_MONTH_DATE_SUMMARY_TAB_INFORMATION: append("GET_CALL_LOAN_MONTH_DATE_SUMMARY_TAB_INFORMATION"),
  CALL_PROCESS_BORROWER: append("CALL_PROCESS_BORROWER"),
  CALL_PROCESS_PORTFOLIO: append("CALL_PROCESS_PORTFOLIO"),
  //> buckets
  GET_CALL_SPREADS_AMOUNT: append("GET_CALL_SPREADS_AMOUNT"),
  GET_CALL_DEBTORS_CONCENTRATION: append("GET_CALL_DEBTORS_CONCENTRATION"),
  //> notify
  CALL_SEND_EMAIL_NOTIFICATION: append("CALL_SEND_EMAIL_NOTIFICATION"),
  GET_CALL_LATEST_NOTE: append("GET_CALL_LATEST_NOTE")
};

export const getters = {
  GET_LOANS_BY_AR_TYPE: append("GET_AR_TYPE_LOANS"),
  GET_LOANS_BY_AR_TYPE_SORTED: append("GET_AR_TYPE_LOANS_SORTED"),
  GET_LOANS_BY_IN_TYPE: append("GET_IN_TYPE_LOANS"),
  GET_LOANS_BY_IN_TYPE_SORTED: append("GET_IN_TYPE_LOANS_SORTED"),
  GET_LOANS_NON_AR_OR_IN: append("GET_LOANS_NON_AR_OR_IN")
};

export const mutations = {
  //> list
  SET_LOADING_LIST: append("SET_LOADING_LIST"),
  SET_ERROR_LIST: append("SET_ERROR_LIST"),
  SET_LIST: append("SET_LIST"),
  //> current activity
  SET_LOADING_CURRENT_ACTIVITY: append("SET_LOADING_CURRENT_ACTIVITY"),
  SET_ERROR_CURRENT_ACTIVITY: append("SET_ERROR_CURRENT_ACTIVITY"),
  SET_CURRENT_ACTIVITY: append("SET_CURRENT_ACTIVITY"),
  //> current activity
  SET_LOADING_MONTH_DATE: append("SET_LOADING_MONTH_DATE"),
  SET_ERROR_MONTH_DATE: append("SET_ERROR_MONTH_DATE"),
  SET_MONTH_DATE: append("SET_MONTH_DATE"),
  //> global
  SET_CURRENT_LOAN: append("SET_CURRENT_LOAN"),
  //> spread
  SET_LOADING_SPREAD: append("SET_LOADING_SPREAD"),
  SET_ERROR_SPREAD: append("SET_ERROR_SPREAD"),
  SET_SPREAD: append("SET_SPREAD"),
  //> debtor
  SET_LOADING_DEBTOR: append("SET_LOADING_DEBTOR"),
  SET_ERROR_DEBTOR: append("SET_ERROR_DEBTOR"),
  SET_DEBTOR: append("SET_DEBTOR"),
  //> Notify
  SET_NOTIFYING_LO: append("SET_NOTIFYING_LO"),
  SET_NOTIFYING_CLIENT: append("SET_NOTIFYING_CLIENT"),
  //> Note
  SET_LOADING_NOTE: append("SET_LOADING_NOTE"),
  SET_ERROR_NOTE: append("SET_ERROR_NOTE"),
  SET_NOTE: append("SET_NOTE")
};
